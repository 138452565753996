import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Skeleton } from 'antd';

import { getAllDeliveries } from 'actions/DeliveriesActions';
import { getSortingFacilityData } from 'services/ops-control';
import { fetchBusinesses } from 'services/business';
import { exportXlsx } from 'services/deliveries';
import {
  DATE_FORMAT,
  DELIVERIES_TYPE,
  RECEIVED_AT_SF_CARDS_INFO,
  SORTING_FACILITY_FILTERS,
  SORTING_FACILITY_LOST_OR_DAMAGED_DELIVERIES_COUNT,
  SORTING_FACILITY_PENDING_DELIVERIES_COUNT,
  TABLE_COLUMNS,
  TO_SORTING_FACILITY_CARDS_IFNO
} from 'constants/sorting-facility';
import {
  DELIVERY_CHILD_STATES,
  DELIVERY_STATES_CODES
} from 'constants/shipments';
import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import { CAIRO_SORTING_FACILITY_ID, ORDER_TYPES } from 'constants/hubs';
import { sumOfTotalReceived } from 'utils/sorting-facility';
import { getPaginationCount } from 'utils/helpers';
import { downloadFromUrl } from 'utils/download';
import DeliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import { formatType } from 'utils/deliveries';

import { notify } from 'components/Notify/Notify';
import CrumbsBar from 'components/OpsControl/CrumbsBar/CrumbsBar';
import SortingFacilityFilter from 'components/OpsControl/SortingFacility/SortingFacilityFilter/SortingFacilityFilter';
import GeneralInfoCard from 'components/OpsControl/GeneralInfoCard/GeneralInfoCard';
import BRTable from 'components/BRTable/BRTable';
import SingleInfoCard from 'components/OpsControl/SingleCardInfo/SingleCardInfo';
import PreviousDateCard from 'components/OpsControl/PreviousDateCard/PreviousDateCard';

import { ReactComponent as SortingIcon } from 'assets/bosta-icons/shipment.svg';
import { ReactComponent as CircleCheck } from 'assets/bosta-icons/Circle-Check.svg';
import { ReactComponent as DispatchIcon } from 'assets/bosta-icons/dispatching.svg';
import { ReactComponent as MultiOrdersIcon } from 'assets/bosta-icons/multi-orders.svg';

import './SortingFacility.less';

const SortingFacility = ({ intl }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [sortingFacilityData, setSortingFacilityData] = useState(null);
  const [businessesOptions, setBusinessesOptions] = useState();
  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const getTablePayload = ({
    page,
    limit,
    orderState,
    business,
    orderType,
    searchInputText,
    isExport
  }) => {
    return isExport
      ? {
          stateCodes: orderState || [
            `${DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE.toString()}&${
              DELIVERY_CHILD_STATES.READY_TO_DISPATCH
            }`,
            DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE.toString()
          ],
          ...(business && { businessId: business }),
          ...(searchInputText && {
            trackingNumberOrSealNumber: searchInputText
          }),
          ...(orderType && { type: orderType }),
          hubs: CAIRO_SORTING_FACILITY_ID
        }
      : {
          data: {
            stateCodes: orderState || [
              `${DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE.toString()}&${
                DELIVERY_CHILD_STATES.READY_TO_DISPATCH
              }`,
              DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE.toString()
            ],
            ...(business && { businessId: business }),
            ...(searchInputText && {
              trackingNumberOrSealNumber: searchInputText
            }),
            ...(orderType && { type: orderType }),
            hubs: CAIRO_SORTING_FACILITY_ID,
            pageNumber: page,
            pageLimit: limit
          }
        };
  };

  const fetchSortingFacilityData = async () => {
    try {
      setLoading(true);
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      const payload = { date: formattedDate };
      const { data } = await getSortingFacilityData(payload);
      setSortingFacilityData(data);
      setLoading(false);
    } catch (error) {
      notify(error.msg);
    }
    setLoading(false);
  };

  const formatTableData = (data) => {
    const formattedData = data.map((order) => {
      const lastReceived = dayjs(
        order.state?.receivedAtWarehouse?.time
      )?.format(DATE_FORMAT);

      const collectedFromBusiness = dayjs(order.collectedFromBusiness)?.format(
        DATE_FORMAT
      );

      const isSortingHub = dayjs(order.firstHub?.time).isSame(
        dayjs(order.state?.receivedAtWarehouse?.time)
      );
      return {
        tracking_number: (
          <Link
            to={`/deliveries/${order.trackingNumber}/details`}
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            {order.trackingNumber}
          </Link>
        ),
        business_name: order?.sender?.name,
        order_type: order?.type.includes(ORDER_TYPES[0].value)
          ? MAP_DELIVERY_TYPES.SEND
          : formatType(order),
        order_state: DeliveryStateFormatter.getStateName(order),
        picked_up: isSortingHub ? collectedFromBusiness : lastReceived,
        last_receive: lastReceived,
        seal_number:
          order.sealNumber || intl.formatMessage({ id: 'common.empty_field' })
      };
    });
    return formattedData;
  };

  const fetchBusinessesData = async () => {
    try {
      const businessesOptions = await fetchBusinesses();
      setBusinessesOptions(
        businessesOptions?.map((business) => {
          return { label: business.name, value: business._id };
        })
      );
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchTableData = async ({
    page,
    limit,
    orderState,
    business,
    orderType,
    searchInputText
  }) => {
    const payload = getTablePayload({
      page,
      limit,
      orderState,
      business,
      orderType,
      searchInputText,
      isExport: false
    });

    const res = await getAllDeliveries({
      ...payload
    });
    return {
      list: formatTableData(res.deliveries),
      total: getPaginationCount({
        page,
        result: res.deliveries,
        pageSize: limit
      })
    };
  };

  const handleUrlDownload = () => {
    downloadFromUrl(sortingFacilityData?.url, 'orders');
  };
  const exportOrders = async ({
    orderState,
    business,
    orderType,
    searchInputText
  }) => {
    try {
      setIsExporting(true);
      const payload = getTablePayload({
        orderState,
        business,
        orderType,
        searchInputText,
        isExport: true
      });
      const res = await exportXlsx(payload);
      notify(res.msg, intl.formatMessage({ id: 'common.success' }));
      setIsExporting(false);
    } catch (error) {
      notify(error.message);
    }
    setIsExporting(false);
  };

  useEffect(async () => {
    await fetchBusinessesData();
  }, []);

  useEffect(() => {
    fetchSortingFacilityData();
  }, [selectedDate]);

  return (
    <div className="sorting-facility__sorting-facility-container">
      <div className="sorting-facility__sorting-facility-header">
        <p className="heading">
          {intl.formatMessage({ id: 'ops_control.sorting_facility_label' })}
        </p>
        <SortingFacilityFilter
          loading={loading}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <div className="sorting-facility__general-cards-container">
        <GeneralInfoCard
          loading={loading}
          cardsData={TO_SORTING_FACILITY_CARDS_IFNO(sortingFacilityData)}
          Icon={SortingIcon}
          title={intl.formatMessage({
            id: 'ops_control.sorting_facility.to_sorting_facility'
          })}
        />
        <GeneralInfoCard
          loading={loading}
          cardsData={RECEIVED_AT_SF_CARDS_INFO(sortingFacilityData)}
          Icon={CircleCheck}
          title={intl.formatMessage({
            id: 'ops_control.sorting_facility.recieved_at_sorting_facility'
          })}
          total={intl.formatMessage(
            {
              id: 'ops_control.sorting_facility.total_recieved_at_sf'
            },
            { total: sumOfTotalReceived(sortingFacilityData) }
          )}
        />
      </div>
      <div className="sorting-facility__dispatching-card">
        <div className="sorting-facility__dispatching__header">
          <DispatchIcon />
          <h3 className="display-xs sorting-facility__dispatching-title">
            {intl.formatMessage({
              id: 'ops_control.dispatching.dispatching_title'
            })}
          </h3>
          {loading ? (
            <Skeleton active paragraph={{ rows: 0 }} />
          ) : (
            <h3 className="sorting-facility__dispatching-total display-xs">
              {intl.formatMessage(
                {
                  id: 'ops_control.dispatching.over_all_recieved'
                },
                { total: sortingFacilityData?.overall_received_count || 0 }
              )}
            </h3>
          )}
        </div>
        <CrumbsBar />
        <div className="sorting-facility__crumb-bar-data">
          <div>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <h4 className="display-xs">
                {sortingFacilityData?.ready_to_dispatch_deliveries_count || 0}
                <span className="caption">
                  {intl.formatMessage(
                    {
                      id: 'ops_control.dispatching.seals_number'
                    },
                    {
                      seals_number:
                        sortingFacilityData?.ready_to_dispatch_seals_count || 0
                    }
                  )}
                </span>
              </h4>
            )}
            <h6>
              {intl.formatMessage({
                id: 'ops_control.dispatching.ready_to_dispatch'
              })}
            </h6>
          </div>
          <div>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <h4 className="display-xs">
                {sortingFacilityData?.in_transit_deliveries_count || 0}
                <span className="caption">
                  {intl.formatMessage(
                    {
                      id: 'ops_control.dispatching.seals_number'
                    },
                    {
                      seals_number:
                        sortingFacilityData?.in_transit_seals_count || 0
                    }
                  )}
                </span>
              </h4>
            )}
            <h6>
              {intl.formatMessage({
                id: 'ops_control.dispatching.dispatched'
              })}
            </h6>
          </div>
          <div>
            {loading ? (
              <Skeleton active paragraph={{ rows: 0 }} />
            ) : (
              <h4 className="display-xs">
                {sortingFacilityData?.completed_deliveries_count || 0}
                <span className="caption">
                  {intl.formatMessage(
                    {
                      id: 'ops_control.dispatching.seals_number'
                    },
                    {
                      seals_number:
                        sortingFacilityData?.completed_seals_count || 0
                    }
                  )}
                </span>
              </h4>
            )}
            <h6>
              {intl.formatMessage({
                id: 'ops_control.dispatching.completed'
              })}
            </h6>
          </div>
        </div>
        <div className="sorting-facility__dispatching-info-container">
          <div className="sorting-facility__pending_delievries">
            {SORTING_FACILITY_PENDING_DELIVERIES_COUNT(sortingFacilityData).map(
              (el) => {
                return (
                  <SingleInfoCard
                    loading={loading}
                    title={el.title}
                    data={el.data}
                    type={DELIVERIES_TYPE.PENDING}
                  />
                );
              }
            )}
          </div>
          <div className="sorting-facility__lost-or-damaged-deliveries">
            {SORTING_FACILITY_LOST_OR_DAMAGED_DELIVERIES_COUNT(
              sortingFacilityData
            ).map((el) => {
              return (
                <SingleInfoCard
                  loading={loading}
                  title={el.title}
                  data={el.data}
                  type={DELIVERIES_TYPE.DAMAGED}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="sorting-facility__orderes-overview-table">
        {sortingFacilityData?.url ? (
          <PreviousDateCard
            loading={isExporting}
            downloadFunction={handleUrlDownload}
            exportButtonTitle={intl.formatMessage({ id: 'common.export' })}
            Icon={MultiOrdersIcon}
            header={intl.formatMessage({
              id: 'ops_control.orders_table.header'
            })}
            exportHeader={intl.formatMessage({
              id: 'ops_control.orders_table.export_orders'
            })}
            discreption={intl.formatMessage({
              id: 'ops_control.orders_table.export_orders_discreption'
            })}
          />
        ) : (
          <BRTable
            title={
              <div>
                <MultiOrdersIcon />
                {intl.formatMessage({ id: 'ops_control.orders_table.header' })}
              </div>
            }
            columns={TABLE_COLUMNS}
            listFunction={fetchTableData}
            tableFilters={SORTING_FACILITY_FILTERS(businessesOptions)}
            showFilter
            searchPlaceholder={intl.formatMessage({
              id: 'ops_control.orders_table.search_placeholder'
            })}
            showSearch
            pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
            exportListFileFunction={exportOrders}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(SortingFacility);
