import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import {
  HUB_VIEW_TABLE_COLUMNS,
  SINGLE_CARDS_INFOS
} from 'constants/hub-overview';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import {
  PENDING_TRANSIT_DATE_FORMAT,
  TOGGLE_BUTTON_INITAL_VALUE,
  TOGGLE_BUTTONS_LIST
} from 'constants/pending-transit';
import {
  exportDispatchingRoomDeliveries,
  getDispatchingRoomDeliveries
} from 'services/ops-control';
import { getPaginationCount } from 'utils/helpers';
import { formatType } from 'utils/deliveries';
import { MAP_DELIVERY_TYPES } from 'constants/Deliveries';
import { ORDER_TYPES } from 'constants/hubs';

import BRToggleButton from 'components/BRToggleButton/BRToggleButton';
import PendingTransitFilter from 'components/OpsControl/PendingTransit/PendingTransitFilter/PendingTransitFilter';
import SingleInfoCard from 'components/OpsControl/SingleCardInfo/SingleCardInfo';
import { notify } from 'components/Notify/Notify';
import BRTable from 'components/BRTable/BRTable';
import BRTag from 'components/BRTag/BRTag';

import BackArrow from 'assets/imgRevamp/arrow-left.svg';

import './HubView.less';

const HubView = ({ hubDetails, setHubDetails, intl }) => {
  const refreshTable = useRef();

  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [toggleButtonValue, setToggleButtonValue] = useState(
    TOGGLE_BUTTON_INITAL_VALUE
  );
  const [cardData, setCardData] = useState({
    toBeDispatched: 0,
    pending: 0,
    addedToRoute: 0,
    dispatched: 0,
    late: 0,
    missed: 0
  });

  const changeToggleButton = (value) => {
    setToggleButtonValue(value);
    refreshTable.current({
      filterValues: {
        toggleButton: value,
        selectedDate: selectedDate
      }
    });
  };

  const changeDateValue = (dateValue) => {
    setSelectedDate(dateValue);
    refreshTable.current({
      filterValues: {
        toggleButton: toggleButtonValue,
        selectedDate: dateValue
      }
    });
  };

  const renderComments = (issues) => {
    const issuesArray = issues?.split(',');

    return (
      <Tooltip
        overlayClassName="br-dispatching__issues-tooltip"
        title={
          <div>
            {issuesArray.map((issue, index) => (
              <div key={index}>- {issue.replace(/_/g, ' ')}</div>
            ))}
          </div>
        }
        getPopupContainer={(trigger) => trigger.parentElement.parentElement}
      >
        <div className="br-dispatching__issues-tooltip-text">
          {issuesArray.length}{' '}
          {intl.formatMessage({
            id: 'monitor_hub_operations.common.issues_title'
          })}
        </div>
      </Tooltip>
    );
  };

  function getHubCounts(hubDetails, toggleButton) {
    const counts = {
      toBeDispatched: 0,
      pending: 0,
      addedToRoute: 0,
      dispatched: 0,
      late: 0,
      missed: 0
    };

    switch (toggleButton) {
      case TOGGLE_BUTTONS_LIST[1].value:
        counts.toBeDispatched = hubDetails?.to_be_dispatched_forward_count;
        counts.pending = hubDetails?.pending_not_received_forward_count;
        counts.addedToRoute = hubDetails?.added_to_route_forward_count;
        counts.dispatched = hubDetails?.dispatched_forward_count;
        counts.late = hubDetails?.late_forward_count;
        counts.missed = hubDetails?.missed_forward_count;
        break;

      case TOGGLE_BUTTONS_LIST[2].value:
        counts.toBeDispatched = hubDetails?.to_be_dispatched_return_count;
        counts.pending = hubDetails?.pending_not_received_return_count;
        counts.addedToRoute = hubDetails?.added_to_route_return_count;
        counts.dispatched = hubDetails?.dispatched_return_count;
        counts.late = hubDetails?.late_return_count;
        counts.missed = hubDetails?.missed_return_count;
        break;

      default:
        counts.toBeDispatched =
          hubDetails?.to_be_dispatched_forward_count +
          hubDetails?.to_be_dispatched_return_count;
        counts.pending =
          hubDetails?.pending_not_received_forward_count +
          hubDetails?.pending_not_received_return_count;
        counts.addedToRoute =
          hubDetails?.added_to_route_forward_count +
          hubDetails?.added_to_route_return_count;
        counts.dispatched =
          hubDetails?.dispatched_forward_count +
          hubDetails?.dispatched_return_count;
        counts.late =
          hubDetails?.late_forward_count + hubDetails?.late_return_count;
        counts.missed =
          hubDetails?.missed_forward_count + hubDetails?.missed_return_count;
        break;
    }

    return counts;
  }

  const formatData = (data, toggleButton) => {
    const { toBeDispatched, dispatched, late, missed, addedToRoute, pending } =
      getHubCounts(hubDetails, toggleButton);

    setCardData({
      toBeDispatched: toBeDispatched,
      pending: pending,
      addedToRoute: addedToRoute,
      dispatched: dispatched,
      late: late,
      missed: missed
    });
    const formattedTableData = data?.map((el) => {
      return {
        tracking_number: (
          <Link
            to={`/deliveries/${el?.tracking_number}/details`}
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            {el?.tracking_number}
          </Link>
        ),
        comments: renderComments(el?.issues),
        attempts: el?.attempts_count,
        order_type: el?.type.includes(ORDER_TYPES[0].value)
          ? MAP_DELIVERY_TYPES.SEND
          : formatType(el),
        hub_aging: (
          <BRTag className={'hub-delieveries__table-tag'}>
            {Math.ceil(dayjs().diff(dayjs(el?.received_at), 'day', true))}
          </BRTag>
        ),
        e2e_aging: (
          <BRTag className={'hub-delieveries__table-tag'}>
            {Math.ceil(dayjs().diff(dayjs(el?.e2e_sla_date), 'day', true))}
          </BRTag>
        )
      };
    });
    return formattedTableData;
  };

  const handleGoBackClick = () => setHubDetails();

  const fetchTableData = async ({ page, limit, filterValues }) => {
    try {
      setLoading(true);
      const payload = {
        date: filterValues?.selectedDate
          ? filterValues?.selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(filterValues?.toggleButton === TOGGLE_BUTTONS_LIST[1].value && {
          isForwardDelivery: true
        }),
        ...(filterValues?.toggleButton === TOGGLE_BUTTONS_LIST[2].value && {
          isForwardDelivery: false
        })
      };
      const res = await getDispatchingRoomDeliveries(hubDetails._id, payload);
      const result = res.data?.slice((page - 1) * limit, limit);
      setLoading(false);
      return {
        list: formatData(res?.data, filterValues?.toggleButton),
        total: getPaginationCount({
          page,
          result: result,
          pageSize: limit
        })
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const exportTableData = async ({ filterValues }) => {
    try {
      const payload = {
        date: filterValues?.selectedDate
          ? filterValues?.selectedDate?.format(PENDING_TRANSIT_DATE_FORMAT)
          : dayjs().format(PENDING_TRANSIT_DATE_FORMAT),
        ...(filterValues?.toggleButton === TOGGLE_BUTTONS_LIST[1].value && {
          isForwardDelivery: true
        }),
        ...(filterValues?.toggleButton === TOGGLE_BUTTONS_LIST[2].value && {
          isForwardDelivery: false
        })
      };

      const res = await exportDispatchingRoomDeliveries(payload);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  return (
    <>
      <div className="hub-deliveries__header">
        <img
          role="button"
          onClick={handleGoBackClick}
          alt="Go Back"
          src={BackArrow}
          className="br-header__back-button"
        />
        <p className="display-xs hub-deliveries__title">
          {hubDetails?.warehouse_name}
        </p>
      </div>

      <div className="pending-transit__header">
        <BRToggleButton
          loading={loading}
          buttonsList={TOGGLE_BUTTONS_LIST}
          setValue={setToggleButtonValue}
          value={toggleButtonValue}
          changeToggleButton={changeToggleButton}
        />
        <PendingTransitFilter
          loading={loading}
          intl={intl}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          changeDateValue={changeDateValue}
        />
      </div>
      <div className="pending-transit__cards-container hubsOverview-container">
        {SINGLE_CARDS_INFOS(cardData).map(({ data, title }) => {
          return <SingleInfoCard loading={loading} data={data} title={title} />;
        })}
      </div>
      <div className="pending-transit__hubs-table-container">
        <BRTable
          exportListFileFunction={exportTableData}
          shareMethods={acceptMethods}
          listFunction={fetchTableData}
          pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
          columns={HUB_VIEW_TABLE_COLUMNS}
          hideFilterButton
          title={intl.formatMessage({
            id: 'ops_control.pending_transit.hubs_table.title'
          })}
        />
      </div>
    </>
  );
};
export default HubView;
