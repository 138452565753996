import Icon from '@ant-design/icons';

import { ORDER_TYPES_VALUE } from './shipment-details';
import { BARCODES_SHEET_TYPES } from 'common/countries/constants/hubs';

import deliveryStateFormatter from 'containers/Deliveries/common/DeliveryStateFormatter';
import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as CheckIcon } from 'assets/bosta-icons/Check.svg';

const {
  DELIVER,
  FXF_SEND,
  CASH_COLLECTION,
  RTO,
  CRP,
  EXCHANGE,
  SIGN_AND_RETURN
} = ORDER_TYPES_VALUE;

export const MAP_DELIVERY_TYPES = {
  CUSTOMER_RETURN_PICKUP: 'CRP',
  CASH_COLLECTION: 'Cash Collection',
  EXCHANGE: 'Exchange',
  SEND: 'Send',
  RTO: 'RTO'
};

export const ORDER_TYPES = [
  { label: 'Send', value: 'SEND' },
  {
    label: 'Exchange',
    value: 'EXCHANGE, EXCHANGE-return, EXCHANGE-forward'
  },
  {
    label: 'Cash Collection',
    value: 'CASH_COLLECTION'
  },
  {
    label: 'Customer Return Pickup (CRP)',
    value: 'CUSTOMER_RETURN_PICKUP'
  },
  {
    label: 'Sign & Return',
    value: 'SIGN_AND_RETURN'
  }
];

export const MAP_PICKUPS_TYPES = {
  'Business Pickup': 'FM'
};

export const PENDING_ORDERS_CODES = [40, 41, 22, 102];
export const COMPLETED_ORDERS_CODES = [24, 45, 46, 47, 48, 42];
export const COMPLETED_ORDERS_REACHES_FINAL_STATES_CODES = [45, 46];
export const LAST_MILE_PICKUPS_CODES = [23, 42];
export const RECEIVED_AT_WAREHOUSE_CODE = [24];
export const ORDER_EDITABLE_STATES_CODE = [20, 22, 24, 30, 41, 47];
export const EXCEPTION_ORDERS_CODES = [47];
export const OFD_ORDERS_CODES = [20, 22, 40, 41];
export const PENDING_PICKUPS = 'Picking up';
export const COMPLETED_PICKUPS = ['Picked up', 'Canceled', 'Exception'];
export const BUSINESS_PICKUP = 'Business Pickup';
export const SOHO_PICKUP = 'Soho Pickup';
export const WHATSAPP_ACTION_LEVEL = 'WHATSAPP_ACTION_LEVEL';
export const WHATSAPP_ADDRESS_LOG = 'WHATSAPP_ADDRESS_LOG';
export const WHATSAPP_SCHEDULED_DATE_LOG = 'WHATSAPP_SCHEDULED_DATE_LOG';
export const WHATSAPP_ADDRESS_AND_SCHEDULED_DATE_LOG =
  'WHATSAPP_ADDRESS_AND_SCHEDULED_DATE_LOG';
export const WHATSAPP_CONFIRM_EXCEPTION_LOG = 'WHATSAPP_CONFIRM_EXCEPTION_LOG';
export const WHATSAPP_MESSAGE_SENDING_LOG = 'WHATSAPP_MESSAGE_SENDING_LOG';

export const MAP_PACKAGE_SIZES = {
  HEAVY_BULKY: 'Heavy Bulky',
  LIGHT_BULKY: 'Light Bulky',
  WHITE_BAG: 'White Bag',
  NORMAL: 'Normal',
  X_LARGE: 'X-Large',
  LARGE: 'Large'
};

export const exceptionsMapping = (delivery) => {
  if (delivery.state?.lastExceptionCode) {
    switch (delivery.type) {
      case DELIVER:
      case FXF_SEND:
        return {
          1: fmt({
            id: `orders.states_logs.exceptions.10.1`
          }),
          2: fmt({
            id: `orders.states_logs.exceptions.10.2`
          }),
          3: fmt(
            {
              id: `orders.states_logs.exceptions.10.3`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          4: fmt({
            id: `orders.states_logs.exceptions.10.${
              delivery.state.waitingForBusinessAction ? 'new4' : 'accept4'
            }`
          }),
          5: fmt({
            id: `orders.states_logs.exceptions.10.5`
          }),
          13: fmt({
            id: `orders.states_logs.exceptions.10.${
              delivery.state.waitingForBusinessAction
                ? 'address5'
                : delivery.state.businessActionTaken
                ? 'address_change_5'
                : 'address_not_changes_5'
            }`
          }),
          14: fmt({
            id: `orders.states_logs.exceptions.10.${
              delivery.state.waitingForBusinessAction
                ? 'phone5'
                : delivery.state.businessActionTaken
                ? 'phone_change_5'
                : 'phone_not_changes_5'
            }`
          }),
          16: fmt({
            id: `orders.states_logs.exceptions.10.16`
          }),
          6: fmt({
            id: `orders.states_logs.exceptions.10.6`
          }),
          7: fmt({
            id: `orders.states_logs.exceptions.10.7`
          }),
          8: fmt({
            id: `orders.states_logs.exceptions.10.8`
          }),
          12: fmt({
            id: `orders.states_logs.exceptions.10.12`
          }),
          100: fmt({
            id: `orders.states_logs.exceptions.10.100`
          }),
          101: fmt({
            id: `orders.states_logs.exceptions.10.101`
          }),
          103: fmt({
            id: `orders.states_logs.exceptions.10.103`
          })
        };
      case CASH_COLLECTION:
        return {
          1: fmt({
            id: `orders.states_logs.exceptions.15.1`
          }),
          2: fmt({
            id: `orders.states_logs.exceptions.15.2`
          }),
          3: fmt(
            {
              id: `orders.states_logs.exceptions.15.3`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          5: fmt({
            id: `orders.states_logs.exceptions.15.5`
          }),
          13: fmt({
            id: `orders.states_logs.exceptions.15.${
              delivery.state.waitingForBusinessAction
                ? 'address5'
                : delivery.state.businessActionTaken
                ? 'address_change_5'
                : 'address_not_changes_5'
            }`
          }),
          14: fmt({
            id: `orders.states_logs.exceptions.15.${
              delivery.state.waitingForBusinessAction
                ? 'phone5'
                : delivery.state.businessActionTaken
                ? 'phone_change_5'
                : 'phone_not_changes_5'
            }`
          }),
          16: fmt({
            id: `orders.states_logs.exceptions.10.16`
          }),
          6: fmt({
            id: `orders.states_logs.exceptions.15.6`
          }),
          7: fmt({
            id: `orders.states_logs.exceptions.15.7`
          }),
          10: fmt({
            id: `orders.states_logs.exceptions.15.10`
          }),
          12: fmt({
            id: `orders.states_logs.exceptions.15.12`
          }),
          100: fmt({
            id: `orders.states_logs.exceptions.15.100`
          }),
          101: fmt({
            id: `orders.states_logs.exceptions.15.101`
          }),
          103: fmt({
            id: `orders.states_logs.exceptions.10.103`
          })
        };
      case RTO:
        return {
          20: fmt({
            id: `orders.states_logs.exceptions.20.20`
          }),
          21: fmt(
            {
              id: `orders.states_logs.exceptions.20.21`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          22: fmt({
            id: `orders.states_logs.exceptions.20.22`
          }),
          23: fmt({
            id: `orders.states_logs.exceptions.20.23`
          }),
          24: fmt({
            id: `orders.states_logs.exceptions.20.24`
          }),
          25: fmt({
            id: `orders.states_logs.exceptions.20.25`
          }),
          12: fmt({
            id: `orders.states_logs.exceptions.20.12`
          }),
          26: fmt({
            id: `orders.states_logs.exceptions.20.26`
          }),
          27: fmt({
            id: `orders.states_logs.exceptions.20.27`
          }),
          28: fmt({
            id: `orders.states_logs.exceptions.20.28`
          }),
          29: fmt({
            id: `orders.states_logs.exceptions.20.29`
          }),
          30: fmt({
            id: `orders.states_logs.exceptions.20.30`
          }),
          100: fmt({
            id: `orders.states_logs.exceptions.20.100`
          }),
          101: fmt({
            id: `orders.states_logs.exceptions.20.101`
          }),
          103: fmt({
            id: `orders.states_logs.exceptions.10.103`
          }),
          16: fmt({
            id: `orders.states_logs.exceptions.10.16`
          })
        };
      case CRP:
        return {
          1: fmt({
            id: `orders.states_logs.exceptions.25.1`
          }),
          2: fmt({
            id: `orders.states_logs.exceptions.25.2`
          }),
          3: fmt(
            {
              id: `orders.states_logs.exceptions.25.3`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          5: fmt({
            id: `orders.states_logs.exceptions.25.5`
          }),
          13: fmt({
            id: `orders.states_logs.exceptions.25.${
              delivery.state.waitingForBusinessAction
                ? 'address5'
                : delivery.state.businessActionTaken
                ? 'address_change_5'
                : 'address_not_changes_5'
            }`
          }),
          14: fmt({
            id: `orders.states_logs.exceptions.25.${
              delivery.state.waitingForBusinessAction
                ? 'phone5'
                : delivery.state.businessActionTaken
                ? 'phone_change_5'
                : 'phone_not_changes_5'
            }`
          }),
          16: fmt({
            id: `orders.states_logs.exceptions.10.16`
          }),
          6: fmt({
            id: `orders.states_logs.exceptions.25.6`
          }),
          7: fmt({
            id: `orders.states_logs.exceptions.25.7`
          }),
          9: fmt({
            id: `orders.states_logs.exceptions.25.9`
          }),
          20: fmt({
            id: `orders.states_logs.exceptions.20.20`
          }),
          21: fmt(
            {
              id: `orders.states_logs.exceptions.20.21`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          22: fmt({
            id: `orders.states_logs.exceptions.20.22`
          }),
          23: fmt({
            id: `orders.states_logs.exceptions.20.23`
          }),
          24: fmt({
            id: `orders.states_logs.exceptions.20.24`
          }),
          25: fmt({
            id: `orders.states_logs.exceptions.20.25`
          }),
          12: fmt({
            id: `orders.states_logs.exceptions.20.12`
          }),
          100: fmt({
            id: `orders.states_logs.exceptions.25.100`
          }),
          101: fmt({
            id: `orders.states_logs.exceptions.25.101`
          }),
          103: fmt({
            id: `orders.states_logs.exceptions.10.103`
          })
        };
      case EXCHANGE:
        return {
          1: fmt({
            id: `orders.states_logs.exceptions.30.1`
          }),
          2: fmt({
            id: `orders.states_logs.exceptions.30.2`
          }),
          3: fmt(
            {
              id: `orders.states_logs.exceptions.30.3`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          4: fmt({
            id: `orders.states_logs.exceptions.30.${
              delivery.state.waitingForBusinessAction ? 'new4' : 'accept4'
            }`
          }),
          5: fmt({
            id: `orders.states_logs.exceptions.30.5`
          }),
          13: fmt({
            id: `orders.states_logs.exceptions.30.${
              delivery.state.waitingForBusinessAction
                ? 'address5'
                : delivery.state.businessActionTaken
                ? 'address_change_5'
                : 'address_not_changes_5'
            }`
          }),
          14: fmt({
            id: `orders.states_logs.exceptions.30.${
              delivery.state.waitingForBusinessAction
                ? 'phone5'
                : delivery.state.businessActionTaken
                ? 'phone_change_5'
                : 'phone_not_changes_5'
            }`
          }),
          16: fmt({
            id: `orders.states_logs.exceptions.10.16`
          }),
          6: fmt({
            id: `orders.states_logs.exceptions.30.6`
          }),
          7: fmt({
            id: `orders.states_logs.exceptions.30.7`
          }),
          8: fmt({
            id: `orders.states_logs.exceptions.30.8`
          }),
          20: fmt({
            id: `orders.states_logs.exceptions.20.20`
          }),
          21: fmt(
            {
              id: `orders.states_logs.exceptions.20.21`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          22: fmt({
            id: `orders.states_logs.exceptions.20.22`
          }),
          23: fmt({
            id: `orders.states_logs.exceptions.20.23`
          }),
          24: fmt({
            id: `orders.states_logs.exceptions.20.24`
          }),
          25: fmt({
            id: `orders.states_logs.exceptions.20.25`
          }),
          12: fmt({
            id: `orders.states_logs.exceptions.20.12`
          }),
          100: fmt({
            id: `orders.states_logs.exceptions.30.100`
          }),
          101: fmt({
            id: `orders.states_logs.exceptions.30.101`
          }),
          103: fmt({
            id: `orders.states_logs.exceptions.10.103`
          })
        };

      case SIGN_AND_RETURN:
        return {
          1: fmt({
            id: `orders.states_logs.exceptions.35.1`
          }),
          2: fmt({
            id: `orders.states_logs.exceptions.35.2`
          }),
          3: fmt(
            {
              id: `orders.states_logs.exceptions.35.3`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          4: fmt({
            id: `orders.states_logs.exceptions.35.${
              delivery.state.waitingForBusinessAction ? 'new4' : 'accept4'
            }`
          }),
          5: fmt({
            id: `orders.states_logs.exceptions.35.5`
          }),
          13: fmt({
            id: `orders.states_logs.exceptions.35.${
              delivery.state.waitingForBusinessAction
                ? 'address5'
                : delivery.state.businessActionTaken
                ? 'address_change_5'
                : 'address_not_changes_5'
            }`
          }),
          14: fmt({
            id: `orders.states_logs.exceptions.35.${
              delivery.state.waitingForBusinessAction
                ? 'phone5'
                : delivery.state.businessActionTaken
                ? 'phone_change_5'
                : 'phone_not_changes_5'
            }`
          }),
          16: fmt({
            id: `orders.states_logs.exceptions.10.16`
          }),
          6: fmt({
            id: `orders.states_logs.exceptions.35.6`
          }),
          7: fmt({
            id: `orders.states_logs.exceptions.35.7`
          }),
          8: fmt({
            id: `orders.states_logs.exceptions.35.8`
          }),
          9: fmt({
            id: `orders.states_logs.exceptions.35.9`
          }),
          20: fmt({
            id: `orders.states_logs.exceptions.20.20`
          }),
          21: fmt(
            {
              id: `orders.states_logs.exceptions.20.21`
            },
            {
              date: deliveryStateFormatter._formatDate(
                delivery.scheduledAt,
                true
              )
            }
          ),
          22: fmt({
            id: `orders.states_logs.exceptions.20.22`
          }),
          23: fmt({
            id: `orders.states_logs.exceptions.20.23`
          }),
          24: fmt({
            id: `orders.states_logs.exceptions.20.24`
          }),
          25: fmt({
            id: `orders.states_logs.exceptions.20.25`
          }),
          12: fmt({
            id: `orders.states_logs.exceptions.20.12`
          }),
          100: fmt({
            id: `orders.states_logs.exceptions.35.100`
          }),
          101: fmt({
            id: `orders.states_logs.exceptions.35.101`
          }),
          103: fmt({
            id: `orders.states_logs.exceptions.10.103`
          })
        };
    }
  }
};

export const DELIVERY_NEEDED_ACTIONS_TYPES = [
  { label: 'Return to shipper', value: 'Return to shipper' },
  { label: 'Redispatch tomorrow', value: 'Redispatch tomorrow' },
  {
    label: 'Change delivery address and Reschedule',
    value: 'Change delivery address and Reschedule'
  },
  { label: 'Change delivery address', value: 'Change delivery address' },
  { label: 'Change Customer phone', value: 'Customer change phone number' },
  { label: 'Schedule delivery', value: 'Schedule delivery' }
];

export const WHATSAPP_NEEDED_ACTIONS_TYPES = [
  { label: 'Change delivery address', value: 10 },
  { label: 'Reschedule delivery', value: 20 },
  { label: 'RTO Updates', value: 80 }
];

export const PACKAGE_SIZES = [
  { value: 'Normal', label: 'Normal', isHidden: true },
  { value: 'Large', label: 'Large', isHidden: true },
  { value: 'White Bag', label: 'XX-large (White Bag)', isHidden: false },
  { value: 'X-Large', label: 'X-Large', isHidden: true },
  { value: 'Light Bulky', label: 'Light Bulky', isHidden: false },
  { value: 'Heavy Bulky', label: 'Heavy Bulky', isHidden: false }
];
export const CLERK_IDENTIFIER = 1;
export const STAR_IDERNTIFIER = 2;
export const LEADER_IDERNTIFIER = 3;

export const SHELF = 'shelf';
export const BIN = 'bin';
export const ROUTE = 'route';
export const TRANSFER_TO_HUB = 'transfer_to_hub';
export const TRANSFER_TO_SORTING_FACILITY = 'sortingFacility';
export const RTO_DELIVERIES_REPORT = 'RTODeliveriesReport';
export const RECEIVE_MANY = 'receiveMany';
export const RECEIVED_BY_MISTAKE = 'receivedByMistake';
export const TERMINATE = 'terminate';
export const BUSINESS = 'business';
export const SHELVES = 'shelves';
export const ROW_TYPE = {
  GROUP: 'group',
  DELIVERY: 'delivery'
};
export const HUB_TYPES = [
  {
    label: fmt({
      id: 'create_edit_hubs.fulfillment_hub'
    }),
    value: 'FULFILLMENT_HUB'
  },
  {
    label: fmt({
      id: 'create_edit_hubs.first_mile_hub_type'
    }),
    value: 'FIRST_MILE_HUB'
  },
  {
    label: fmt({
      id: 'create_edit_hubs.last_mile_hub_type'
    }),
    value: 'LAST_MILE_HUB'
  },
  {
    label: fmt({
      id: 'create_edit_hubs.sorting_hub_type'
    }),
    value: 'SORTING_HUB'
  },
  {
    label: fmt({
      id: 'create_edit_hubs.managerial_hub_type'
    }),
    value: 'MANAGERIAL_HUB'
  }
];

export const RTO_DISPATCHING_ACTION_TYPES = {
  route: 'Assign to Route',
  bin: 'Assign to Bin',
  transfer_to_hub: 'Transfer'
};

// This ID is for Mohandseen Hub as the story of BR-7202 says:
// If the admin is not assigned to a hub, Mohandseen Hub should be selected by default.
export const DEFAULT_HUB_ID = 'gX7ZErsWaTwFQuQsj';

// This ID is for Cairo Sorting Facility Hub as the story of BR-7204 says:
// Destination hub should be populated by the “Cairo Sorting Facility”
export const DEFAULT_DESTINATION_HUB_ID = 'roJgwM9rzPPRHW2BY';

export const ACTION_TYPES = {
  ADDRESS_UPDATED: fmt({
    id: 'action_center.order_details_updated'
  }),
  UPDATED_DELIVERY_DETAILS: fmt({
    id: 'action_center.order_details_updated'
  }),
  RESCHEDULED: fmt({
    id: 'audit_actions.order_summary_tooltip.rescheduled'
  }),
  RESCHEDULED_TOMORROW: fmt({
    id: 'audit_actions.order_summary_tooltip.rescheduled'
  }),
  CANCEL_ORDER: fmt({
    id: 'action_center.cancelled'
  }),
  REJECTED_RETURN: fmt({ id: 'hubs.receive_from_hub.rejected_return' })
};

export const ACTION_TYPES_TAG_COLOR = {
  ADDRESS_UPDATED: 'blue',
  UPDATED_DELIVERY_DETAILS: 'blue',
  RESCHEDULED: 'yellow',
  RESCHEDULED_TOMORROW: 'yellow',
  CANCEL_ORDER: 'red',
  REJECTED_RETURN: 'red'
};

export const CAIRO_SORTING_FACILITY_ID = 'roJgwM9rzPPRHW2BY';
export const CAIRO_SORTING_FACILITY_LABEL = 'Cairo Sorting Facility';

export const DEFAULT_PACKAGE_WEIGHT = 1;

export const DEFAULT_GEOMETRY_TYPE = 'Polygon';

export const FIRST_MILE_HUB = 'FIRST_MILE_HUB';
export const LAST_MILE_HUB = 'LAST_MILE_HUB';
export const SORTING_HUB = 'SORTING_HUB';
export const SORTING_STATION_HUB = 'SORTING_STATION_HUB';
export const INTEGRATION_HUB = 'INTEGRATION_HUB';
export const INTERNATIONAL_HUB = 'INTERNATIONAL_HUB';
export const REVERSE_HUB = 'REVERSE_HUB';

export const HUB_TRANSFER_SEAL_LIMIT = 50;
export const SEAL_DISPATCH_LIMIT = 500;

export const SEAL_SCAN_TYPE = {
  NORMAL: 'Normal',
  MASTER: 'Master',
  BULKY: 'Bulky',
  BINS: 'Bins'
};

export const PENDING = 'Pending';
export const SEALS = 'Seals';
export const SEAL_NUMBER = 'sealNumber';
export const TRACKING_NUMBER = 'trackingNumber';

export const SEAL_STATE = {
  'In Transit': fmt({
    id: 'hubs.hub_transfer.dispatch_seals.seal_state.in_transit'
  }),
  Pending: fmt({
    id: 'hubs.hub_transfer.dispatch_seals.seal_state.pending'
  }),
  Completed: fmt({
    id: 'hubs.hub_transfer.dispatch_seals.seal_state.completed'
  }),
  Deleted: fmt({
    id: 'hubs.hub_transfer.dispatch_seals.seal_state.deleted'
  }),
  Investigation: fmt({
    id: 'hubs.hub_transfer.dispatch_seals.seal_state.investigation'
  })
};

export const SEAL_STATE_COLOR = {
  'In Transit': 'br-state-blue-group',
  Pending: 'br-state-yellow-group',
  Completed: 'br-state-green-group',
  Deleted: 'br-state-red-group',
  Investigation: 'br-state-red-group'
};

const SEAL_STATE_MENU = [
  {
    label: fmt({
      id: 'hubs.hub_transfer.dispatch_seals.seal_state.in_transit'
    }),
    value: 'In Transit'
  },
  {
    label: fmt({
      id: 'hubs.hub_transfer.dispatch_seals.seal_state.pending'
    }),
    value: 'Pending'
  },
  {
    label: fmt({
      id: 'hubs.hub_transfer.dispatch_seals.seal_state.completed'
    }),
    value: 'Completed'
  },
  {
    label: fmt({
      id: 'hubs.hub_transfer.dispatch_seals.seal_state.investigation'
    }),
    value: 'Investigation'
  }
];

const SEARCH_TYPE_MENU = [
  {
    label: fmt({
      id: 'hubs.hub_transfer.dispatch_seals.search_type.seals'
    }),
    value: 'Seals'
  },
  {
    label: fmt({
      id: 'hubs.hub_transfer.dispatch_seals.search_type.bins'
    }),
    value: 'Bins'
  }
];

export const HUB_TRANSFER_DISPATCH_TABLE_FILTERS = [
  {
    label: fmt({ id: 'hubs.hub_transfer.dispatch_seals.seal_state.title' }),
    menu: SEAL_STATE_MENU,
    key: 'state'
  },
  {
    label: fmt({ id: 'hubs.hub_transfer.dispatch_seals.search_type.title' }),
    menu: SEARCH_TYPE_MENU,
    menuType: 'radio',
    key: 'searchType'
  }
];

export const HUB_TRANSFER_SCANNING_TYPE = ({ destinationHubId }) => [
  {
    label: fmt({ id: 'hubs.hub_transfer.transfer_seals.scanning_type.seals' }),
    value: 'Normal'
  },
  {
    label: fmt({ id: 'hubs.hub_transfer.transfer_seals.scanning_type.bulky' }),
    value: 'Bulky',
    disabled: !destinationHubId
  },
  {
    label: fmt({ id: 'hubs.hub_transfer.transfer_seals.scanning_type.bins' }),
    value: 'Bins'
  }
];

export const HUB_RECEIVE_SCANNING_TYPE = [
  {
    label: fmt({ id: 'hubs.hub_transfer.transfer_seals.scanning_type.bins' }),
    value: 'Bins'
  }
];

export const HUB_TRANSFER_DISPATCHING_TYPES = ['Normal', 'Bulky'];

export const IN_TRANSIT = 'In Transit';
export const NORMAL = 'Normal';
export const DEFAULT = 'Default';

export const REJECTED_RETURN = 'REJECTED_RETURN';

// Barcodes to AWB
export const NUMBER_OF_SHEETS = {
  [BARCODES_SHEET_TYPES.ZEBRA]: {
    MIN: 10,
    MAX: 90
  },
  [BARCODES_SHEET_TYPES.CSV]: {
    MIN: 100,
    MAX: 10000
  },
  [BARCODES_SHEET_TYPES.A5]: { MIN: 100, MAX: 10000 }
};

export const BARCODE_SHEET_TO_VAR_MAP = {
  [BARCODES_SHEET_TYPES.ZEBRA]: 'numberOfSheets',
  [BARCODES_SHEET_TYPES.A5]: 'numberOfSheets',
  [BARCODES_SHEET_TYPES.CSV]: 'numberOfBarcodes'
};

export const NUMBER_OF_STICKERS_PER_SHEET = 10;

export const ACTIONS = {
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT'
};

export const BARCODES_COUNTERS_CARDS = [
  {
    title: fmt({ id: 'barcode_to_awb.cards.not_printed_yet' }),
    dataIndex: 'notPrintedCount',
    icon: <Icon component={PlusIcon} className="ant-icon-md" />,
    iconColor: 'yellow'
  },
  {
    title: fmt({ id: 'barcode_to_awb.cards.printed' }),
    dataIndex: 'printedCount',
    icon: <Icon component={CheckIcon} className="ant-icon-md" />,
    iconColor: 'green'
  }
];

export const TASKS_TAB_COLUMNS_TYPE = {
  PICKUPS: 'pickups',
  ORDERS: 'orders'
};

export const CHANGE_FROM_COD_TO_OTHER_SOURCE_OPTIONS = [
  'Axis Wallet',
  'Valu',
  'Flash'
];

export const CCOD = 'CCOD';
export const COD = 'COD';
export const AXIS_WALLET = 'Axis Wallet';
export const VALU = 'Valu';
export const FLASH = 'Flash';

export const MONEY_SOURCES_CAN_BE_CHANGED_TO_COD_SOURCE = [
  CCOD,
  AXIS_WALLET,
  VALU,
  FLASH
];

export const GENERAL_RECEIVING = 'General';

export const HUB_RECEIVING_MANIFEST_COLUMNS = [
  {
    title: fmt({ id: 'hubs.receiving_manifest.columns.manifest_name' }),
    dataIndex: 'manifestTitle'
  },
  {
    title: fmt({ id: 'deliveries.search_filters.createdAt' }),
    dataIndex: 'createdAt'
  },
  {
    title: fmt({ id: 'hubs.receiving_manifest.columns.star_name' }),
    dataIndex: 'starName'
  },
  {
    title: fmt({ id: 'hubs.receiving_manifest.columns.deliveries' }),
    dataIndex: 'numberOfDeliveries'
  },
  {
    title: '',
    dataIndex: 'action',
    width: 80
  }
];

export const MAX_PENDING_BULKY_SEALS = 3;

export const HUB_RECEIVE_FROM_SORTING_OPTIONS = [
  {
    label: fmt({ id: 'hubs.hub_transfer.transfer_seals.scanning_type.seals' }),
    value: SEAL_SCAN_TYPE.NORMAL
  },
  {
    label: fmt({ id: 'hubs.hub_transfer.transfer_seals.scanning_type.bulky' }),
    value: SEAL_SCAN_TYPE.BULKY
  }
];

export const WRONG_SCAN_TABLE_COLUMNS = [
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.tracking_number' }),
    dataIndex: 'trackingNumberLink'
  },
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.business' }),
    dataIndex: 'businessName'
  },
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.source_hub' }),
    dataIndex: 'sourceHub'
  },
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.destination_hub' }),
    dataIndex: 'destinationHub'
  },
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.last_user_hub' }),
    dataIndex: 'userHub'
  },
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.last_action_user' }),
    dataIndex: 'user'
  },
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.last_action' }),
    dataIndex: 'lastAction'
  },
  {
    title: fmt({ id: 'hubs.wrong_scan.table_columns.scanning_entry_point' }),
    dataIndex: 'scanningEntryPoint'
  }
];

export const DELIVERIES_SCAN_TYPES = {
  SORTING_RECEIVE_ORDER_ENTRY_SCAN: 'SortingReceiveOrderScanRemoved',
  PICKUPS_RECEIVE_ORDER_ENTRY_SCAN: 'PickupsReceiveOrderScanRemoved',
  RTO_DELIVERY_SCAN: 'RTODeliveriesScan',
  TRANSFER_TO_HUB_SCAN: 'TransferToHubScan',
  RECEIVE_ORDER_SCAN: 'ReceiveOrderScan',
  ROUTES_ORDER_SCAN: 'RoutesOrderScan',
  SORTING_RECEIVE_ORDER_SCAN: 'SortingReceiveOrderScan',
  PICKUPS_RECEIVE_ORDER_SCAN: 'PickupsReceiveOrderScan',
  FIRST_MILE_ORDER_SCAN: 'FirstMileOrderScan',
  VENDOR_DELIVERY_SCAN: 'VendorDeliveryScan'
};
export const WRONG_SCAN_TABS = {
  WRONG_SCANS: 'wrongScans',
  REMOVED_SCANS: 'removedScans'
};
export const SCAN_TYPE_MAPPING = {
  [DELIVERIES_SCAN_TYPES.PICKUPS_RECEIVE_ORDER_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.receive_new_pickup'
  }),
  [DELIVERIES_SCAN_TYPES.PICKUPS_RECEIVE_ORDER_ENTRY_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.receive_new_pickup'
  }),
  [DELIVERIES_SCAN_TYPES.SORTING_RECEIVE_ORDER_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.receive_in_transit'
  }),
  [DELIVERIES_SCAN_TYPES.SORTING_RECEIVE_ORDER_ENTRY_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.receive_in_transit'
  }),
  [DELIVERIES_SCAN_TYPES.TRANSFER_TO_HUB_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.transfer'
  }),
  [DELIVERIES_SCAN_TYPES.ROUTES_ORDER_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.routes'
  }),
  [DELIVERIES_SCAN_TYPES.VENDOR_DELIVERY_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.vendor'
  }),
  [DELIVERIES_SCAN_TYPES.RTO_DELIVERY_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.dispatch_rto'
  }),
  [DELIVERIES_SCAN_TYPES.RECEIVE_ORDER_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.debrief'
  }),
  [DELIVERIES_SCAN_TYPES.FIRST_MILE_ORDER_SCAN]: fmt({
    id: 'hubs.wrong_scan.scan_types.first_mile'
  })
};

export const WRONG_SCAN_QUICK_FILTERS = [
  {
    label: fmt({ id: 'hubs.wrong_scan.quick_filters.wrong_scan_logs' }),
    value: false
  },
  {
    label: fmt({
      id: 'hubs.wrong_scan.quick_filters.wrong_scan_not_receiveed'
    }),
    value: WRONG_SCAN_TABS.WRONG_SCANS
  },
  {
    label: fmt({
      id: 'hubs.wrong_scan.quick_filters.removed_scans'
    }),
    value: WRONG_SCAN_TABS.REMOVED_SCANS
  }
];

export const WRONG_SCAN_HUB_FILTERS_NAMES = [
  'sourceHubId',
  'destinationHubId',
  'scannedInHubId'
];
