import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

import { DATE_FORMAT } from 'constants/date-picker';

const OpsDatePicker = ({ selectedDate, setSelectedDate, loading }) => {
  const disabledDate = (current) => {
    return current && current.isAfter(dayjs().endOf('day'));
  };

  const handleChange = (dateString) => {
    setSelectedDate(dateString || dayjs());
  };

  return (
    <DatePicker
      disabled={loading}
      value={dayjs(selectedDate)}
      onChange={handleChange}
      format={DATE_FORMAT}
      disabledDate={disabledDate}
    />
  );
};

export default OpsDatePicker;
