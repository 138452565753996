import { Tooltip } from 'antd';

import { getStatusTag } from 'utils/fulfillment';
import { checkSmartDate, dates } from 'utils/helpers';

import { fmt } from 'components/IntlWrapper/IntlWrapper';
import CreatePO from 'components/NewFulfillment/FulfillmentInbound/components/CreatePO/CreatePO';
import ViewPO from 'components/NewFulfillment/FulfillmentInbound/components/ViewPO/ViewPO';
import POList from 'components/NewFulfillment/FulfillmentInbound/components/POList/POList';
import EditPO from 'components/NewFulfillment/FulfillmentInbound/components/EditPO/EditPO';
import BusinessesList from 'components/NewFulfillment/Businesses/components/BusinessesList/BusinessesList';
import BusinessTabs from 'components/NewFulfillment/Businesses/components/BusinessesList/components/BusinessTabs/BusinessTabs';
import ProductsList from 'components/NewFulfillment/FulfillmentProducts/components/ProductsList/ProductsList';
import OutboundList from 'components/NewFulfillment/FulfillmentOutbound/components/OutboundList/OutboundList';
import SalesOrder from 'components/NewFulfillment/FulfillmentOutbound/components/SalesOrder/SalesOrder';

import { ReactComponent as TooltipsIcon } from 'assets/imgRevamp/InformationButtonWithoutFilter.svg';

export const PAGE_SIZE_DEFAULT = 50;
export const INVENTORY_PAGE_SIZE_OPTIONS = [50, 100, 200];

export const CATEGORIES = [
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.general'
    }),
    id: 1
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.computing'
    }),
    id: 30
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.laptops'
    }),
    id: 31
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.desktop_computers'
    }),
    id: 32
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.computer_accessories_&_components'
    }),
    id: 33
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.networking_products'
    }),
    id: 34
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.electronics'
    }),
    id: 25
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.t.vs_&_videos'
    }),
    id: 26
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.home_audio'
    }),
    id: 27
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.cameras'
    }),
    id: 28
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.headphones'
    }),
    id: 29
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.fashion'
    }),
    id: 6
  },
  {
    title: fmt({
      id: "fulfillment.inventory.table.category.category_list.men's_fashion"
    }),
    id: 16
  },
  {
    title: fmt({
      id: "fulfillment.inventory.table.category.category_list.women's_fashion"
    }),
    id: 17
  },
  {
    title: fmt({
      id: "fulfillment.inventory.table.category.category_list.kid's_fashion"
    }),
    id: 18
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.gaming'
    }),
    id: 35
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.play_station_5'
    }),
    id: 36
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.play_station_4'
    }),
    id: 37
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.xbox'
    }),
    id: 38
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.pc_gaming'
    }),
    id: 39
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.health_&_beauty'
    }),
    id: 20
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.beauty_&_personal_care'
    }),
    id: 40
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.hair_care'
    }),
    id: 41
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.fragrance'
    }),
    id: 42
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.make_up'
    }),
    id: 43
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.health_care'
    }),
    id: 44
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.phones_&_tablets'
    }),
    id: 21
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.mobile_phones'
    }),
    id: 22
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.tablets'
    }),
    id: 23
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.category_list.mobile_accessories'
    }),
    id: 24
  }
];

export const PRODUCT_DETAILS_HEADERS = [
  'sales_price',
  'product_category',
  'product_sku',
  'product_type.title',
  'invoicing_policy.title',
  'internal_reference'
];

export const FULFILLMENT_DETAILS = {
  header: [
    'total_sales',
    'avg_orders',
    'avg_time',
    'delivery_avg_time',
    'damaged_orders',
    'rto'
  ],
  content: ['38,555 EGP', '7', '5', '3', '19', '5']
};

export const PRODUCT_TYPE = {
  consu: fmt({
    id: 'fulfillment.inventory.product_details.product_type.consumable'
  }),
  service: fmt({
    id: 'fulfillment.inventory.product_details.product_type.service'
  }),
  product: fmt({
    id: 'fulfillment.inventory.product_details.product_type.product'
  })
};

export const INVOICING_POLICY = {
  order: fmt({
    id: 'fulfillment.inventory.product_details.invoicing_policy.order'
  }),
  delivery: fmt({
    id: 'fulfillment.inventory.product_details.invoicing_policy.delivery'
  })
};

export const INVENTORY_TABLE_COLUMNS = [
  {
    title: fmt({
      id: 'fulfillment.inventory.table.bosta_sku'
    }),
    dataIndex: 'bostaSKU',
    key: 'bostaSKU'
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.product_name'
    }),
    dataIndex: 'Name',
    key: 'Name'
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.price.title'
    }),
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: (
      <Tooltip
        title={fmt({
          id: 'fulfillment.inventory.table.quantity.onhand_quantity_tooltip'
        })}
        trigger={['click', 'hover']}
        className="br-inventory-table__quantity-tooltip"
      >
        {fmt({
          id: 'fulfillment.inventory.table.quantity.onhand_quantity'
        })}
        <TooltipsIcon />
      </Tooltip>
    ),
    dataIndex: 'qty_available',
    key: 'qty_available'
  },
  {
    title: (
      <Tooltip
        title={fmt({
          id: 'fulfillment.inventory.table.quantity.quantity_tooltip'
        })}
        trigger={['click', 'hover']}
        className="br-inventory-table__quantity-tooltip"
      >
        {fmt({
          id: 'fulfillment.inventory.table.quantity.title'
        })}
        <TooltipsIcon />
      </Tooltip>
    ),
    dataIndex: 'quantity',
    key: 'quantity'
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.category.title'
    }),
    dataIndex: 'category',
    key: 'category'
  },
  {
    title: fmt({
      id: 'fulfillment.inventory.table.action.title'
    }),
    dataIndex: 'action',
    width: 70
  }
];

export const PURCHASE_STATES = {
  PROCESSING: 'waiting',
  RECEIVED: 'fully_received',
  PARTIALLY_RECEIVED: 'partially_received',
  CANCELED: 'cancel'
};

const REQUEST_STATUS = [
  {
    label: fmt({ id: 'fulfillment.inventory_requests.filters.processing' }),
    value: PURCHASE_STATES.PROCESSING
  },
  {
    label: fmt({ id: 'fulfillment.inventory_requests.filters.received' }),
    value: PURCHASE_STATES.RECEIVED
  },
  {
    label: fmt({
      id: 'fulfillment.inventory_requests.filters.partially_received'
    }),
    value: PURCHASE_STATES.PARTIALLY_RECEIVED
  },
  {
    label: fmt({ id: 'fulfillment.inventory_requests.filters.canceled' }),
    value: PURCHASE_STATES.CANCELED
  }
];

export const FILTERS = [
  {
    label: fmt({ id: 'fulfillment.inventory_requests.filters.received_date' }),
    key: 'recieved',
    menuType: 'date'
  },
  {
    label: fmt({ id: 'fulfillment.inventory_requests.filters.request_status' }),
    menu: REQUEST_STATUS,
    key: 'requestState'
  }
];

export const INVENTORY_REQUESTS_COLUMNS = ({ businessId }) => {
  return [
    {
      title: fmt({
        id: `fulfillment.inventory_requests.table_columns.request_id`
      }),
      dataIndex: 'name',
      render: (name) =>
        name || (
          <span className="br-table__empty-field">
            {fmt({
              id: `common.empty_field`
            })}
          </span>
        ),
      width: 130
    },
    {
      title: fmt({
        id: `fulfillment.inventory_requests.table_columns.bosta_warehouses`
      }),
      dataIndex: 'picking_type_id',
      render: (picking_type_id) =>
        picking_type_id?.length ? (
          picking_type_id[1]
        ) : (
          <span className="br-table__empty-field">
            {fmt({
              id: `common.empty_field`
            })}
          </span>
        ),
      width: 300
    },
    {
      title: fmt({
        id: `fulfillment.inventory_requests.table_columns.no_of_products`
      }),
      dataIndex: 'order_line',
      render: (order_line) =>
        order_line?.length || (
          <span className="br-table__empty-field">
            {fmt({
              id: `common.empty_field`
            })}
          </span>
        )
    },
    {
      title: fmt({
        id: `fulfillment.inventory_requests.table_columns.request_date`
      }),
      dataIndex: 'date_order_str',
      render: (request_date) => {
        //Odoo sends date -2 hours
        const formattedDate = new Date(request_date);
        formattedDate.setHours(formattedDate.getHours() + 2);
        return request_date ? (
          <>
            <div>{checkSmartDate(formattedDate)}</div>
            <div className="br-inventory-table__time caption">
              {dates(formattedDate, 'h:mm A')}
            </div>
          </>
        ) : (
          <span className="br-table__empty-field">
            {fmt({
              id: `common.empty_field`
            })}
          </span>
        );
      }
    },
    {
      title: fmt({
        id: `fulfillment.inventory_requests.filters.received_date`
      }),
      dataIndex: 'date_effective_str',
      render: (received_date) => {
        const formattedDate = new Date(received_date);
        formattedDate.setHours(formattedDate.getHours() + 2);
        return received_date ? (
          <>
            <div>{checkSmartDate(formattedDate)}</div>
            <div className="br-inventory-table__time caption">
              {dates(formattedDate, 'h:mm A')}
            </div>
          </>
        ) : (
          <span className="br-table__empty-field">
            {fmt({
              id: `common.empty_field`
            })}
          </span>
        );
      }
    },
    {
      title: fmt({
        id: `fulfillment.inventory_requests.table_columns.status`
      }),
      dataIndex: 'receipt_status',
      render: (status, request) => (
        <span
          className="br-inventory-requests__status-tag"
          onClick={(e) => e.stopPropagation()}
        >
          {getStatusTag(status, request, businessId)}
        </span>
      )
    }
  ];
};

export const REQUESTS_QUICK_FILTERS = [
  {
    label: fmt({ id: 'fulfillment.inventory_requests.filters.all' }),
    value: []
  },
  {
    label: fmt({
      id: 'fulfillment.inventory_requests.filters.processing'
    }),
    value: [PURCHASE_STATES.PROCESSING]
  },
  {
    label: fmt({ id: 'fulfillment.inventory_requests.filters.received' }),
    value: [PURCHASE_STATES.RECEIVED]
  },
  {
    label: fmt({
      id: 'fulfillment.inventory_requests.filters.partially_received'
    }),
    value: [PURCHASE_STATES.PARTIALLY_RECEIVED]
  },
  {
    label: fmt({
      id: 'fulfillment.inventory_requests.filters.canceled'
    }),
    value: [PURCHASE_STATES.CANCELED]
  }
];

export const SIZES_ARRAY = [
  {
    label: fmt({
      id: `pricing.normal_size`
    }),
    value: 'Normal'
  },
  {
    label: fmt({
      id: `pricing.large_size`
    }),
    value: 'Large'
  },
  {
    label: fmt({
      id: `pricing.x_large_size`
    }),
    value: 'X-Large'
  },
  {
    label: fmt({
      id: `pricing.bulky_size`
    }),
    value: 'Bulky'
  },
  {
    label: fmt({
      id: `pricing.heavy_bulky_size`
    }),
    value: 'Heavy Bulky'
  },
  {
    label: fmt({
      id: `pricing.light_bulky_size`
    }),
    value: 'Light Bulky'
  },
  {
    label: fmt({
      id: `pricing.white_bag_size`
    }),
    value: 'White Bag'
  }
];

export const SHEET_MAX_NUM_OF_PO_PRODUCTS = 100;

export const PO_PRODUCT_OBJ_MAPPING = {
  0: 'sku',
  1: 'productName',
  2: 'quantity'
};

export const PO_PRODUCT_HEADER_MAPPING = [
  'Seller SKU - كود المنتج الخاص بالبيزنس',
  'Item Name - إسم المنتج',
  'Quantity'
];

export const FULFILLMENT_INBOUND_MAIN_PATH = '/fulfillment/inbound';
export const FULFILLMENT_INBOUND_PO_MAIN_PATH = `${FULFILLMENT_INBOUND_MAIN_PATH}/po`;

export const FULFILLMENT_ROUTES = [
  {
    path: `${FULFILLMENT_INBOUND_PO_MAIN_PATH}/create`,
    name: 'Create PO',
    component: CreatePO
  },
  {
    path: `${FULFILLMENT_INBOUND_PO_MAIN_PATH}/:id`,
    name: 'View PO',
    component: ViewPO
  },
  // {
  //   path: `${FULFILLMENT_INBOUND_PO_MAIN_PATH}/:id/edit`,
  //   name: 'Edit PO',
  //   component: EditPO,
  // },
  {
    path: FULFILLMENT_INBOUND_MAIN_PATH,
    name: 'PO List',
    component: POList,
    exact: true
  }
];

export const FULFILLMENT_BUSINESSES_MAIN_PATH = '/fulfillment/businesses';
export const getFulfillmentBusinessTabsPath = (id) =>
  `${FULFILLMENT_BUSINESSES_MAIN_PATH}/${id}`;

export const FULFILLMENT_BUSINESSES_ROUTES = [
  {
    path: FULFILLMENT_BUSINESSES_MAIN_PATH,
    name: 'Businesses List',
    component: BusinessesList,
    exact: true
  }
];

export const FULFILLMENT_PRODUCTS_MAIN_PATH = '/fulfillment/products';

export const FULFILLMENT_PRODUCTS_ROUTES = [
  {
    path: FULFILLMENT_PRODUCTS_MAIN_PATH,
    name: 'Products List',
    component: ProductsList,
    exact: true
  }
];

export const PO_STATE_IDS = {
  CREATED: 1,
  CONFIRMED: 2,
  QUALITY_CHECKED: 3,
  READY_TO_PUTAWAY: 4,
  PARTIALLY_PUTAWAY: 6,
  COMPLETED: 7,
  CANCELED: 5
};

export const PO_STATE_NAMES = {
  [PO_STATE_IDS.CREATED]: 'created',
  [PO_STATE_IDS.CONFIRMED]: 'confirmed',
  [PO_STATE_IDS.QUALITY_CHECKED]: 'putaway',
  [PO_STATE_IDS.READY_TO_PUTAWAY]: 'putaway',
  [PO_STATE_IDS.PARTIALLY_PUTAWAY]: 'putaway',
  [PO_STATE_IDS.COMPLETED]: 'completed',
  [PO_STATE_IDS.CANCELED]: 'canceled'
};

export const PO_STATE_IDS_CAN_EDITED_OR_CANCELED = [
  PO_STATE_IDS.CREATED,
  PO_STATE_IDS.CONFIRMED
];

export const PUT_AWAY_KEY = 'putAway';
export const PO_KEY = 'PO-';
export const PO_ID_KEY = 'poId';
export const SKU_KEY = 'sku';
export const SERIAL_ID_KEY = 'serialId';

export const FULFILLMENT_OUTBOUND_MAIN_PATH = '/fulfillment/outbound';
export const FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH = `${FULFILLMENT_OUTBOUND_MAIN_PATH}/so`;

export const FULFILLMENT_OUTBOUND_ROUTES = [
  {
    path: FULFILLMENT_OUTBOUND_MAIN_PATH,
    name: 'Outbound List',
    component: OutboundList,
    exact: true
  },
  {
    path: `${FULFILLMENT_OUTBOUND_SALES_ORDER_MAIN_PATH}/:id`,
    name: 'View Sales Order',
    component: SalesOrder,
    exact: true
  }
];

export const SALES_ORDER_STATE_IDS = {
  CREATED: 1,
  PICKED: 2,
  PACKED: 3,
  TRANSFERRED: 4,
  CANCELED: 5
};

export const SALES_ORDER_STATE_NAMES = {
  [SALES_ORDER_STATE_IDS.CREATED]: 'created',
  [SALES_ORDER_STATE_IDS.PICKED]: 'picked',
  [SALES_ORDER_STATE_IDS.PACKED]: 'packed',
  [SALES_ORDER_STATE_IDS.TRANSFERRED]: 'transferred',
  [SALES_ORDER_STATE_IDS.CANCELED]: 'canceled'
};

export const SALES_ORDERS_SEARCH_KEYS = {
  SKU: 'sku',
  TRACKING_NUMBER: 'trackingNumber',
  REFERENCE_ID: 'referenceId'
};

export const SALES_ORDERS_SEARCH_OPTIONS = [
  {
    label: fmt({
      id: 'fulfillment_outbound.sales_orders_table.search_options.sku'
    }),
    value: SALES_ORDERS_SEARCH_KEYS.SKU
  },
  {
    label: fmt({
      id: 'fulfillment_outbound.sales_orders_table.search_options.tracking_number'
    }),
    value: SALES_ORDERS_SEARCH_KEYS.TRACKING_NUMBER
  },
  {
    label: fmt({
      id: 'fulfillment_outbound.sales_orders_table.search_options.reference_id'
    }),
    value: SALES_ORDERS_SEARCH_KEYS.REFERENCE_ID
  }
];

export const TABLE_PAGE_SIZE_OPTIONS = [50, 100, 200];
