import { fmt } from 'IntlWrapper/IntlWrapper';

export const DEPOSIT_TYPES = {
  SA: ['Bosta Office Deposit', 'POS Deposit'],
  EG: [
    'Bosta Office Deposit',
    'Fawry Deposit',
    'CIB Deposit',
    'Fawry Payment',
    'Maxab Deposit'
  ]
};

export const DEBREIF_CASH_DEPOSIT_TYPES = {
  SA: ['Bosta Office Deposit', 'POS Deposit', 'Bank Transfer'],
  EG: [
    'Bosta Office Deposit',
    'Fawry Deposit',
    'CIB Deposit',
    'Valu Deposit',
    'Flash Deposit',
    'Maxab Deposit'
  ]
};

export const DEBREIF_STARS_DEPOSIT_TYPES = {
  SA: ['Bosta Office Deposit'],
  EG: [
    'Bosta Office Deposit',
    'Fawry Deposit',
    'CIB Deposit',
    'Valu Deposit',
    'Flash Deposit',
    'Maxab Deposit'
  ]
};

export const MONEY_DEBRIEF_REPORT_DEPOSIT_TYPES = {
  SA: [
    'Bosta Office Deposit',
    'POS Deposit',
    'Bank Transfer',
    'Hub Cashier Deposit'
  ],
  EG: [
    'Fawry Deposit',
    'CIB Deposit',
    'Fawry Payment',
    'Bosta Office Deposit',
    'Axis Deposit',
    'Valu Deposit',
    'Flash Deposit',
    'Donation Receipts',
    'Flash Deposit',
    'Maxab Deposit'
  ],
  QA: ['Bosta Office Deposit']
};

export const CURRENCY = (cod) => ({
  EG: {
    type: 'EGP',
    localized: fmt(
      { id: 'common.EGP_modified' },
      {
        cod
      }
    ),
    reversed_localized: fmt(
      { id: 'common.EGP' },
      {
        cod
      }
    ),
    id: 'common.EGP'
  },
  SA: {
    type: 'SAR',
    localized: fmt(
      { id: 'common.SAR_modified' },
      {
        cod
      }
    ),
    reversed_localized: fmt(
      { id: 'common.SAR' },
      {
        cod
      }
    ),
    id: 'common.SAR'
  },
  AE: {
    type: 'AED',
    localized: fmt(
      { id: 'common.AED_modified' },
      {
        cod
      }
    ),
    reversed_localized: fmt(
      { id: 'common.AED' },
      {
        cod
      }
    ),
    id: 'common.AED'
  },
  QA: {
    type: 'QAR',
    localized: fmt(
      { id: 'common.QAR_modified' },
      {
        cod
      }
    ),
    reversed_localized: fmt(
      { id: 'common.QAR' },
      {
        cod
      }
    ),
    id: 'common.QAR'
  },
  OM: {
    type: 'OMR',
    localized: fmt(
      { id: 'common.OMR_modified' },
      {
        cod: cod
      }
    ),
    reversed_localized: fmt(
      { id: 'common.OMR' },
      {
        cod
      }
    ),
    id: 'common.OMR'
  },
  KW: {
    type: 'KWD',
    localized: fmt(
      { id: 'common.KWD_modified' },
      {
        cod: cod
      }
    ),
    reversed_localized: fmt(
      { id: 'common.KWD' },
      {
        cod
      }
    ),
    id: 'common.KWD'
  }
});

export const VAT = {
  EG: 14,
  SA: 15,
  QA: 5
};

export const INTERNATIONAL_WALLET_VAT = {
  EG: 15,
  AE: 0
};

export const STAR_FAWRY_ID_OWNERSHIP = {
  DEFAULT: false,
  EG: true
};

export const BANK_TRANSFER_AVAILABILITY = {
  EG: false,
  SA: true,
  DEFAULT: false
};

export const CASHIER_PAGE_AVAILABILITY = {
  EG: false,
  SA: true,
  DEFAULT: false
};

export const CURRENCIES = {
  AED: 'AED',
  SAR: 'SAR',
  EGP: 'EGP'
};
